import React, { FC } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { useChat } from '@components/chat/useChat';
import RichText from '@components/RichText/RichText';
import { wrap } from '@sitecore/common';
import { ChatLinksRendering } from '@sitecore/types/manual/ChatLinks';
import {
  Badge,
  Box,
  Button,
  Card,
  Form,
  Grid,
  Heading,
  Hidden,
  Image,
  InputText,
  Stack,
  Stretch,
  Text,
  TextLink,
} from '@sparky';
import { ChatIcon, SendIcon } from '@sparky/icons';

interface FormFields {
  question: string;
}
const ChatLinks: FC<ChatLinksRendering> = ({ fields, params }) => {
  const [mount] = useChat();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormFields>();

  const submitForm: SubmitHandler<FormFields> = ({ question }) => {
    mount({ visibility: 'open', askText: question });
    reset();
  };

  const headingLevel = params?.headingLevel ?? 'h2';

  let chatLinksColumnCount = 3;
  if (params?.chatLinksColumnCount) {
    chatLinksColumnCount = parseInt(params?.chatLinksColumnCount, 10);
  }

  return (
    <Card elevation="L">
      <Stack direction="row">
        <Stack.Item grow>
          <Box paddingY="8" paddingLeft="8" paddingRight={{ initial: '8', lg: undefined }}>
            <Stack gap="6">
              {(fields.tag?.value || fields.tag?.editable) && (
                <Stack.Item>
                  <Badge>{wrap(fields.tag)}</Badge>
                </Stack.Item>
              )}
              {(fields.title?.value || fields.title?.editable) && (
                <Heading as={headingLevel} size="M">
                  {wrap(fields.title)}
                </Heading>
              )}
              {(fields.infoText?.value || fields.infoText?.editable) && (
                <Text>{wrap(fields.infoText, <RichText html={fields.infoText.value} />)}</Text>
              )}
              <Grid columns="1" rowGap="8">
                <Grid.Item gridRow={params?.chatLinksPlacement === 'Below' ? '2' : '1'}>
                  {fields.items.length > 0 && (
                    <Box paddingTop="2">
                      <Grid
                        alignY="start"
                        columnGap="8"
                        gridTemplateColumns={{
                          initial: 'auto',
                          lg: 'auto auto',
                          xl: 'auto '.repeat(chatLinksColumnCount),
                        }}
                        rowGap="6">
                        {fields.items.map(
                          (item, i) =>
                            item.fields?.displayText?.value &&
                            item.fields?.chatTopic?.value && (
                              <TextLink
                                leftIcon={<ChatIcon />}
                                emphasis="medium"
                                onClick={() =>
                                  mount({
                                    visibility: 'open',
                                    topic: item.fields?.chatTopic.value,
                                  })
                                }
                                key={i}>
                                {wrap(item.fields?.displayText)}
                              </TextLink>
                            ),
                        )}
                      </Grid>
                    </Box>
                  )}
                </Grid.Item>
                <Grid.Item>
                  <Form onSubmit={handleSubmit(submitForm)}>
                    <Stack direction={{ initial: 'column', md: 'row' }} gap="3" alignY="end">
                      <Stack.Item grow>
                        <InputText
                          label={fields.questionFormField?.value?.label}
                          hint={fields.questionFormField?.value?.hint}
                          error={errors.question ? fields.questionFormField?.value?.validationMessage : ''}
                          {...register('question', { maxLength: 75 })}
                          placeholder={fields.questionFormField?.value?.placeholder}
                        />
                      </Stack.Item>
                      {(fields.buttonText?.value || fields.buttonText?.editable) && (
                        <Stretch width={{ initial: true, md: false }} height={false}>
                          <Button type="submit" size="compact">
                            <SendIcon />
                            {wrap(fields.buttonText)}
                          </Button>
                        </Stretch>
                      )}
                    </Stack>
                  </Form>
                </Grid.Item>
              </Grid>
            </Stack>
          </Box>
        </Stack.Item>
        {(fields.image?.value?.src || fields.image?.editable) &&
          wrap(
            fields.image,
            <Hidden below="lg">
              <Image src={fields.image.value.src} alt={fields.image.value.alt ?? ''} objectFit="cover" />
            </Hidden>,
          )}
      </Stack>
    </Card>
  );
};

export default ChatLinks;
